$(function(){
	// navigation
	var $navToggle = $('.navigation-toggle');
	var $body = $('body');
	var isNavigation = false;
	$navToggle.on('click', function(){
		if(!isNavigation) {
			$('html,body').animate({scrollTop:0}, 350);
			$navToggle.addClass('navigation-toggle-cross');
			$body.addClass('navigation-visible');
			isNavigation = true;
			return false;
		}
	});
	$body.on('click', function(event){
		var target = $(event.target);
		if (!target.is('#navigation-mobile')) {
			$body.removeClass('navigation-visible');
			$navToggle.removeClass('navigation-toggle-cross');
			isNavigation = false;
		}
	});
	// yandexmap
	$yandexMap = $('#yandexMap');
	if($yandexMap.length){
		ymaps.ready(initMap);
		var yandexMap;
		var yandexMapPoint;
		var yandexMapDefaults = {
			center: [55.75161, 37.618591],
			zoom: 15
		}
		var mapCenter = $yandexMap.data('mapCenter') || yandexMapDefaults.center;
		var mapZoom = $yandexMap.data('mapZoom') || yandexMapDefaults.zoom;
		var mapPointCoords = $yandexMap.data('mapPoint') || false;
		var mapPointLabel = $yandexMap.data('mapPointLabel') || false;
		function initMap(){
			yandexMap = new ymaps.Map("yandexMap", {
				center: mapCenter,
				zoom: mapZoom
			});
			if(mapPointCoords != false){
				if(mapPointLabel != false){
					yandexMapPoint = new ymaps.Placemark(mapPointCoords, {
						iconContent: mapPointLabel
					}, {
						preset: 'islands#greenStretchyIcon'
					});
				}else{
					yandexMapPoint = new ymaps.Placemark(mapPointCoords, {}, {
						preset: 'islands#greenDotIcon'
					});
				}
	    		yandexMap.geoObjects.add(yandexMapPoint);
			}
		}
	}
	// swiper
	var expertsSwiper = new Swiper ('.swiper-experts', {
		slidesPerView: 4,
		spaceBetween: 20,
		breakpoints: {
			480: {
				slidesPerView: 1,
				spaceBetween: 0
			},
			768: {
				slidesPerView: 2
			},
			980: {
				slidesPerView: 3
			}
		},
		grabCursor: true,
		prevButton: '.swiper-button-prev-experts',
		nextButton: '.swiper-button-next-experts'
	});
	var newsSwiper = new Swiper ('.swiper-news', {
		slidesPerView: 4,
		spaceBetween: 20,
		breakpoints: {
			480: {
				slidesPerView: 1,
				spaceBetween: 0
			},
			768: {
				slidesPerView: 2
			},
			980: {
				slidesPerView: 3
			}
		},
		grabCursor: true,
		prevButton: '.swiper-button-prev-news',
		nextButton: '.swiper-button-next-news'
	});
});
